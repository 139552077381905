'use client';

import localServerApi from '@/api/localServer';

import ErrorContainer from '@/components/error-handling/top-level/ErrorContainer';

import useMount from '@/hooks/useMount';

export default function Error({ error, reset }) {
  useMount(() => {
    localServerApi.global.logError({
      message: error?.message,
      digest: error?.digest,
    });

    console.error('Top level error: ', error);
  });

  return <ErrorContainer error={error} reset={reset} />;
}
