import styled, { css } from 'styled-components';
import { Link as RRLink } from 'react-router-dom';

import typeStyle from '@/styles/js/fonts/typeStyles';

export const FooterLarge = styled.footer`
  margin-top: 5rem;
  margin-bottom: 1rem;
`;

export const CopyRight = styled.span`
  ${typeStyle('body-s')}

  color: ${({ theme }) => theme.colors.textLight};
  vertical-align: middle;
  display: inline-block;
  margin-right: 2rem;
`;

export const Links = styled.div`
  display: inline-block;
  margin-right: 2rem;

  & > * + * {
    margin-left: 2rem;
  }
`;

export const Socials = styled.div`
  white-space: nowrap;
  display: inline-block;
  margin-top: 1rem;

  & > * + * {
    margin-left: 1.5rem;
  }
`;

const linkStyles = css`
  ${typeStyle('body-s')}

  color: ${({ theme }) => theme.colors.accent};
  display: inline-block;
  vertical-align: middle;

  &:hover {
    filter: brightness(2);
    transform: scale(1.05);
  }
`;

export const ExternalLink = styled.a.attrs({
  rel: 'noopener noreferrer',
  target: '_blank',
})`
  ${linkStyles}
`;

export const Link = styled(RRLink)`
  ${linkStyles}
`;
